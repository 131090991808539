<template>
  <!-- Popup -->
  <Popup ref="popup" />

  <section
    class="text-center container col-lg-8 col-md-9 col-sm-10 mx-auto mt-2 mb-4"
  >
    <div class="row py-3">
      <div>
        <h1 class="fw-light">
          <strong><span>The EXP Token Shop</span></strong>
        </h1>

        <h4 class="lead text-muted mx-auto my-3">
          <span>
            You can increase the staking power of your NFTClub NFTs with EXP
            tokens.
          </span>
        </h4>

        <h4 class="lead text-muted mx-auto my-3">
          <span>
            Please note that EXP tokens can only be used on the NFTClub
            platform.
          </span>
        </h4>

        <h4 class="lead text-muted mx-auto my-3">
          <span>
            EXP tokens cannot be transferred to other wallets or sold on any DEX
            after you buy them.
          </span>
        </h4>

        <!-- <h2 class="fw-light mt-4">
          <strong>You can get 1 EXP token for</strong>
        </h2> -->
        <h4 class="lead mx-auto my-3">
          <table class="mx-auto my-4" style="width: 80%">
            <tr>
              <th>1 EXP costs</th>
              <th>Your Balance</th>
            </tr>
            <tr v-for="_token in acceptedTokens" :key="_token">
              <td>
                {{ expTokenPrice[_token] / 10 ** decimals[_token] }}
                {{ tokenName[_token] }}
              </td>
              <td>
                {{
                  Math.round(
                    stakersBalanceE0[_token] / 10 ** (decimals[_token] - 3)
                  ) /
                  10 ** 3
                }}
                {{ tokenName[_token] }}
              </td>
            </tr>
          </table>
        </h4>

        <h4 class="lead text-muted mx-auto my-3">
          <span>Please select how many EXP tokens you want.</span>
        </h4>

        <h4 class="lead mx-auto my-4">
          <span>Note that the transaction cannot be undone.</span>
        </h4>
      </div>

      <div class="mb-3 mx-auto">
        <div class="input-group mx-auto">
          <div class="input-group-prepend mx-auto">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="expTokensToBuy = minExpTokensToBuy"
            >
              Min
            </button>
            <button
              v-for="_tokensToBuy in [500, 1000, 5000, 10000, 50000, 100000]"
              :key="_tokensToBuy"
              class="btn btn-outline-secondary"
              type="button"
              :disabled="_tokensToBuy > maxExpTokensToBuy"
              @click="expTokensToBuy = _tokensToBuy"
            >
              {{ _tokensToBuy }}
            </button>
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="calculateMaxExpTokensToBuy"
            >
              Max
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <input
            id="tokensToBuy"
            type="number"
            :min="minExpTokensToBuy"
            class="form-control mx-auto"
            v-model="expTokensToBuy"
          />
        </div>
      </div>

      <span class="row">
        <span class="column">
          <h4>Buy {{ expTokensToBuy }} EXP for:</h4>
          <div class="col-lg-6 col-md-8 mx-auto">
            <!-- <button id="buyButtonEth" type="button" class="btn btn-primary my-2 btn-large" :disabled="!expSaleActive || expTokenPrice['eth'] <= 0"
              @click="buyExpWithEth">{{ Math.round(expTokensToBuy * expTokenPrice['eth'] / 10 ** 12) / 10**6  }} {{ETH}}</button>
            &nbsp; -->
            <button
              v-for="_token in acceptedTokens"
              :key="_token"
              type="button"
              class="btn btn-primary my-2 btn-large"
              :disabled="
                !expSaleActive ||
                expTokenPrice[_token] <= 0 ||
                expTokensToBuy * expTokenPrice[_token] >
                  stakersBalanceE0[_token]
              "
              @click="buyExpWithToken(_token)"
            >
              {{
                Math.round(
                  (expTokensToBuy * expTokenPrice[_token]) /
                    10 ** (decimals[_token] - 3)
                ) /
                10 ** 3
              }}
              {{ tokenName[_token] }}
            </button>
          </div>
        </span>
      </span>
    </div>

    <h1 class="fw-light"><strong>Your EXP tokens</strong></h1>

    <div class="lead text-muted">
      <span>Balance: {{ stakersBalanceE0["expToken"] }} EXP </span>
    </div>
    <div
      class="lead text-muted"
      v-if="
        stakersBalanceE0['expToken'] > 0 &&
        stakersAllowanceOfFor[('expToken', 'leveler')] <= 0
      "
    >
      <span>Remember to approve the EXP token for level upgrades.</span>
    </div>
    <div
      class="lead text-muted"
      v-if="
        stakersBalanceE0['expToken'] > 0 &&
        stakersAllowanceOfFor[('expToken', 'leveler')] > 0
      "
    >
      <span>You are now ready to level up some NFTs!</span>
    </div>

    <br />
    <div class="row py-5">
      <div>
        <h1 class="fw-light">
          <strong>EXP Tokens Approval for Leveling</strong>
        </h1>

        <div class="lead text-muted">
          <span
            >Before you can upgrade the staking power of the NFTs, you have to
            approve the leveler contract as a spender of EXP tokens.</span
          >
        </div>

        <div class="lead text-muted">
          <span>You only need to give your approval once.</span>
        </div>

        <div class="lead text-muted">
          <span
            >If you wish to remove your approval, you can do that here,
            too.</span
          >
        </div>

        <div class="col-12">
          <button
            class="btn btn-success"
            type="button"
            id="approveExpTokenForLeveler"
            :disabled="stakersAllowanceOfFor[('expToken', 'leveler')] > 0"
            @click="approveTokenForContract('expToken', 'leveler')"
          >
            approve
          </button>
          &nbsp;
          <button
            class="btn btn-danger"
            type="button"
            id="removeExpAllowance"
            :disabled="stakersAllowanceOfFor[('expToken', 'leveler')] <= 0"
            @click="removeExpTokenAllowanceForShop"
          >
            (remove approval)
          </button>
        </div>
      </div>
    </div>
    <!-- <br />
    <div class="row">
      <h5>For testing: Change EXP token allowance</h5>
      <div class="lead text-muted"><span>Allowance for Leveling: {{ stakersAllowanceOfFor[('expToken','leveler')] }} EXP
      </span></div>
      <div class="lead text"><span>Use the minus or plus button to lower or raise your EXP token allowance by the amount in the number input box above.
      </span></div>
      <div class="col-12">

        <button class="btn btn-primary" type="button" id="decreaseExpAllowance" @click="decreaseExpTokenAllowance">
          -
        </button>
        <button class="btn btn-primary" type="button" id="increaseExpAllowance" @click="increaseExpTokenAllowance">
          +
        </button>
      </div>
    </div> -->
  </section>
</template>

<script>
import { ethers } from "ethers";
import ExpTokenSeller from "../../artifacts/contracts/ExpTokenSeller.sol/ExpTokenSeller.json";
import ExpToken from "../../artifacts/contracts/ExpToken.sol/ExpToken.json";
import ERC20Basic from "../../artifacts/contracts/ERC20Basic.sol/ERC20Basic.json";

import Popup from "./Popup.vue";

export default {
  components: { Popup },

  data() {
    return {
      provider: null,
      signer: null,
      tokenName: window.tokenName,
      decimals: window.decimals,
      ETH: window.tokenName["eth"],
      NFTC: window.NFTC,

      walletAddress: "",
      stakersAllowanceOfFor: {},
      stakersBalanceE0: [],

      expSaleActive: false,

      expTokensToBuy: 1000,
      minExpTokensToBuy: 10,
      maxExpTokensToBuy: 10000000000000,

      expTokenPrice: {},
      acceptedTokens: window.acceptedTokensExpSeller,

      contract: [],
    };
  },
  methods: {
    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    async handleTx(_msg, _fx) {
      this.showPopup(_msg, "Please confirm the transaction", 0);

      try {
        let tx = await _fx;
        this.showPopup(_msg, "Waiting for transaction to finish", 0);
        await tx.wait();
        this.showPopup(_msg, "was successful", 1000);
      } catch {
        this.showPopup(_msg, "finished", 1000);
      }
    },

    approveTokenForContract: async function (_token, _contract, _msg) {
      let that = this;
      if (typeof _msg === "undefined" || _msg === "") {
        _msg = "Approving " + that.tokenName[_token] + " token";
      }

      await this.handleTx(
        _msg,
        that.contract[_token].approve(
          window.address[_contract],
          ethers.constants.MaxUint256,
          {
            gasLimit: 65000, // 60k is probably fine, too
            gasPrice: window.minGasCostE0,
          }
        )
      );

      that.contract[_token]
        .allowance(that.walletAddress, window.address[_contract])
        .then((_allowance) => {
          if (_allowance <= that.stakersAllowanceOfFor[("exp", "leveler")]) {
            that.showPopup(_msg + " failed.", " Try again later...", 3000);
          }
          that.stakersAllowanceOfFor[("exp", "leveler")] = _allowance;
        });
    },

    removeExpTokenAllowanceForShop: async function () {
      let that = this;

      const _msg = "Removing EXP token approval";

      await that.handleTx(
        _msg,
        that.contract["expToken"].approve(window.address["leveler"], 0, {
          gasLimit: 65000, // 60k is probably fine, too
          gasPrice: window.minGasCostE0,
        })
      );

      that.contract["expToken"]
        .allowance(that.walletAddress, window.address["leveler"])
        .then((_allowance) => {
          if (_allowance > 0) {
            that.showPopup(_msg + " failed.", " Try again later...", 3000);
          }
          that.stakersAllowanceOfFor[("exp", "leveler")] = _allowance;
        });
    },

    buyExpWithEth: async function () {
      let that = this;

      let _expTokensToBuy = Math.floor(that.expTokensToBuy);
      if (_expTokensToBuy !== that.expTokensToBuy) {
        that.showPopup("Only integers allowed!", "", 3000);
        that.expTokensToBuy = _expTokensToBuy;
        return false;
      }

      await this.getExpTokenPrices();

      const _tokenName = "eth";

      const _tokenCost = ethers.BigNumber.from(_expTokensToBuy).mul(
        that.expTokenPrice[_tokenName]
      );
      console.log(_tokenCost);
      console.log(that.expTokenPrice[_tokenName]);

      if (_tokenCost.gt(that.stakersBalanceE0[_tokenName])) {
        that.showPopup(
          `Not enough ${_tokenName}`,
          `You have ${
            Math.round(that.stakersBalanceE0[_tokenName] / 10 ** 15) / 10 ** 3
          } ${_tokenName} in your wallet. You can buy up to ${Math.floor(
            that.stakersBalanceE0[_tokenName].div(
              that.expTokenPrice[_tokenName]
            )
          )} EXP with that.`,
          3000
        );
        return false;
      }

      if (_tokenCost.lte(0)) {
        that.showPopup(
          `${_tokenName} payments are currently not accepted`,
          "",
          3000
        );
        return false;
      }

      if (_expTokensToBuy < that.minExpTokensToBuy) {
        that.showPopup(
          `You need to buy at least ${that.minExpTokensToBuy} EXP tokens`,
          "",
          3000
        );
        return false;
      }

      const _msg = "Buying " + _expTokensToBuy + " EXP tokens";

      const options = {
        value: _tokenCost,
      };
      await that.handleTx(
        _msg,
        that.contract["expSeller"].buyExpWithEth(_expTokensToBuy, options)
      );

      const _oldExpBal = this.stakersBalanceE0["expToken"];
      await this.getBalance("expToken");
      if (_oldExpBal >= this.stakersBalanceE0["expToken"]) {
        that.showPopup(_msg + " failed.", " Try again later...", 3000);
      }
      this.getEthBalance();
    },

    buyExpWithToken: async function (_tokenName) {
      let that = this;

      let _expTokensToBuy = Math.floor(that.expTokensToBuy);
      if (_expTokensToBuy !== that.expTokensToBuy) {
        that.showPopup("Only integers allowed!", "", 3000);
        that.expTokensToBuy = _expTokensToBuy;
        return false;
      }

      console.log(Object.keys(this.contract));

      if (_tokenName === "eth") {
        that.buyExpWithEth();
        return false;
      }

      if (!Object.keys(this.contract).includes(_tokenName)) {
        that.showPopup(
          `${_tokenName} purchases are not possible right now.`,
          "Please try another payment method.",
          3000
        );
        return false;
      }

      await this.getExpTokenPrices();

      let _tokenCost = ethers.BigNumber.from(_expTokensToBuy).mul(
        that.expTokenPrice[_tokenName]
      );
      console.log(_tokenCost);
      console.log(that.expTokenPrice[_tokenName]);
      console.log(that.stakersAllowanceOfFor[(_tokenName, "expShop")]);

      if (_tokenCost.gt(that.stakersBalanceE0[_tokenName])) {
        that.showPopup(
          `Not enough + ${_tokenName}`,
          `You have ${
            Math.round(that.stakersBalanceE0[_tokenName] / 10 ** 15) / 10 ** 3
          } ${_tokenName} in your wallet. You can buy up to ${Math.floor(
            that.stakersBalanceE0[_tokenName].div(
              that.expTokenPrice[_tokenName]
            )
          )} EXP with that.`,
          3000
        );
        return false;
      }

      if (_tokenCost.lte(0)) {
        that.showPopup(
          `${_tokenName} payments are currently not accepted`,
          "",
          3000
        );
        return false;
      }

      if (_tokenCost.gt(that.stakersAllowanceOfFor[(_tokenName, "expShop")])) {
        await that.approveTokenForContract(
          _tokenName,
          "expSeller",
          `Approving ${_tokenName} first...`
        );

        that.stakersAllowanceOfFor[(_tokenName, "expShop")] =
          await that.contract[_tokenName].allowance(
            that.walletAddress,
            window.address["expSeller"]
          );
        if (
          _tokenCost.gt(that.stakersAllowanceOfFor[(_tokenName, "expShop")])
        ) {
          that.showPopup(
            `Approving ${_tokenName} failed.`,
            "Try again later...",
            3000
          );
          return false;
        }
      }

      if (_expTokensToBuy < that.minExpTokensToBuy) {
        that.showPopup(
          `You need to buy at least ${that.minExpTokensToBuy} EXP tokens`,
          "",
          3000
        );
        return false;
      }
      const _msg = "Buying " + _expTokensToBuy + " EXP tokens";

      await that.handleTx(
        _msg,
        that.contract["expSeller"].buyExpWithTokens(
          _expTokensToBuy,
          window.address[_tokenName],
          _tokenCost,
          {
            gasPrice: window.minGasCostE0,
          }
        )
      );

      const _oldExpBal = this.stakersBalanceE0["expToken"];
      await this.getBalance("expToken");
      if (_oldExpBal >= this.stakersBalanceE0["expToken"]) {
        that.showPopup(_msg + " failed.", " Try again later...", 3000);
      }
      this.getBalance(_tokenName);
    },

    getEthBalance: function () {
      this.provider.getBalance(this.walletAddress).then((_balance) => {
        this.stakersBalanceE0["eth"] = ethers.BigNumber.from(_balance);
      });
    },

    getBalance: function (_tokenName) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.contract[_tokenName]
          .balanceOf(that.walletAddress)
          .then((balance) => {
            that.stakersBalanceE0[_tokenName] = ethers.BigNumber.from(balance);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    getAllowanceForShop: function (_tokenName) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.contract[_tokenName]
          .allowance(that.walletAddress, window.address["expSeller"])
          .then((_allowance) => {
            that.stakersAllowanceOfFor[(_tokenName, "expShop")] = _allowance;
            console.log(
              _tokenName,
              that.stakersAllowanceOfFor[(_tokenName, "expShop")],
              _allowance
            );
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    getExpAllowanceForLeveling: function () {
      this.contract["expToken"]
        .allowance(this.walletAddress, window.address["leveler"])
        .then((_allowance) => {
          this.stakersAllowanceOfFor[("exp", "leveler")] =
            ethers.BigNumber.from(_allowance);
        });
    },

    getExpTokenPrices: async function () {
      let _tokenCAs = [];
      for (let i in this.acceptedTokens) {
        let _token = this.acceptedTokens[i];
        if (_token === "eth") {
          continue;
        }
        _tokenCAs.push(window.address[_token]);
      }

      this.contract["expSeller"]
        .getExpPriceInTokensAndEthAndPaused(_tokenCAs)
        .then((pricesE0) => {
          for (let i in this.acceptedTokens) {
            let _token = this.acceptedTokens[i];
            this.expTokenPrice[_token] = ethers.BigNumber.from(pricesE0[i]);
          }

          if (this.acceptedTokens.includes("eth")) {
          this.expTokenPrice["eth"] = ethers.BigNumber.from(
            pricesE0[pricesE0.length - 2]
          );
          }


          this.expSaleActive = pricesE0[pricesE0.length - 1].eq(1)
            ? false
            : true;
          if (this.expSaleActive === false) {
            this.showPopup(
              "Buying EXP Tokens is currently not possible.",
              "Please try again later!",
              3000
            );
          }
        });
    },

    calculateMaxExpTokensToBuy: function () {
      let _currMax = 0;
      for (let i in this.acceptedTokens) {
        let _token = this.acceptedTokens[i]; // VueJS makes it an object instead of an array
        let _price = this.expTokenPrice[_token];
        console.log(_token, _price);

        if (_price.lte(0)) {
          continue;
        }
        let _balance = this.stakersBalanceE0[_token];

        let _maxTokensToBuy = Math.floor(_balance.div(_price));
        if (_token === "eth") {
          --_maxTokensToBuy;
        }
        console.log(_maxTokensToBuy);

        if (_maxTokensToBuy > _currMax) {
          _currMax = _maxTokensToBuy;
        }
      }

      this.maxExpTokensToBuy = _currMax;
      this.expTokensToBuy = _currMax;
    },

    load: async function () {
      this.signer
        .getAddress()
        .then((_myAddress) => {
          this.walletAddress = _myAddress;
        })
        .then(() => {
          console.log(this.walletAddress);
          console.log(this.contract);

          for (let i in this.acceptedTokens) {
            let _token = this.acceptedTokens[i]; // VueJS makes it an object instead of an array
            // console.log(_token);
            if (_token === "eth") {
              continue;
            }
            this.getBalance(_token);
            this.getAllowanceForShop(_token);
          }

          this.getEthBalance();
          this.getBalance("expToken");

          this.getExpTokenPrices();

          this.getExpAllowanceForLeveling();
        });
    },
  },

  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        this.contract["expSeller"] = new ethers.Contract(
          window.address["expSeller"],
          ExpTokenSeller.abi,
          this.provider.getSigner()
        );

        this.contract["expToken"] = new ethers.Contract(
          window.address["expToken"],
          ExpToken.abi,
          this.provider.getSigner()
        );

        for (let i in this.acceptedTokens) {
          let _token = this.acceptedTokens[i]; // VueJS makes it an object instead of an array
          if (_token === "eth") {
            continue;
          }
          this.contract[_token] = new ethers.Contract(
            window.address[_token],
            ERC20Basic.abi,
            this.provider.getSigner()
          );
        }
      })
      .then(() => {
        this.load();
      });
  },
};
</script>

<style scoped>
.input-group {
  z-index: 0;
}
</style>